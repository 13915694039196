export default {
    'AUTHORIZATION_LOAD_ERROR': 'AUTHORIZATION_LOAD_ERROR',
    'AUTHORIZATION_LOAD_SUCCESS': 'AUTHORIZATION_LOAD_SUCCESS',
    'AUTHORIZATION_LOADING': 'AUTHORIZATION_LOADING',
    'AUTHORIZATION_COMMENT_LOADING': 'AUTHORIZATION_COMMENT_LOADING',
    'AUTHORIZATION_FORM_LOAD_SUCCESS': 'AUTHORIZATION_FORM_LOAD_SUCCESS',
    'AUTHORIZATION_SPONSOR': 'AUTHORIZATION_SPONSOR',
    'SET_CURRENT_FORM': 'SET_CURRENT_FORM',
    'SET_CURRENT_STEP': 'SET_CURRENT_STEP',
    'SET_ERROR': 'SET_ERROR',
    'ADD_FILES': 'ADD_FILES',
    'SET_STEP_ERROR': 'SET_STEP_ERROR',
    'SET_BENEFICIARY': 'SET_BENEFICIARY',
    'SET_BENEFICIARY_ERROR': 'SET_BENEFICIARY_ERROR',
    'SET_INPUTS': 'SET_INPUTS',
    'RESET_AUTHORIZATION_STATE': 'RESET_AUTHORIZATION_STATE',
    'SET_REIMBURSEMENT_STATUS': 'SET_REIMBURSEMENT_STATUS',
    'SET_CLAIM_TYPE': 'SET_CLAIM_TYPE',
    'SET_LIST_AUTHORIZATION': 'SET_LIST_AUTHORIZATION',
    'SET_AUTHORIZATION': 'SET_AUTHORIZATION',
    'SET_AUTHORIZATION_ERROR': 'SET_AUTHORIZATION_ERROR',
    'SET_SPONSOR_SLUG': 'SET_SPONSOR_SLUG',
    'SET_IS_REIMBURSEMENT': 'SET_IS_REIMBURSEMENT',
    'SET_COUNTRY_CODE': 'SET_COUNTRY_CODE',
    'SET_CURRENCY_CODE': 'SET_CURRENCY_CODE',
    'SET_CURRENT_COVERAGE': 'SET_CURRENT_COVERAGE',
    'REMOVE_FILE': 'REMOVE_FILE',
    'SET_LOADING_FILE': 'SET_LOADING_FILE',
    'SET_SEARCH_BENEFICIARY_SPONSOR_FORMS': 'SET_SEARCH_BENEFICIARY_SPONSOR_FORMS',
    'SET_SEARCH_BENEFICIARIES_RESULTS': 'SET_SEARCH_BENEFICIARIES_RESULTS',
    'SET_BENEFICIARY_INFORMATION': 'SET_BENEFICIARY_INFORMATION',
    'SET_SELECTED_BENEFICIARY': 'SET_SELECTED_BENEFICIARY',
    'SET_BENEFICIARY_SEARCH_COMPLETED': 'SET_BENEFICIARY_SEARCH_COMPLETED',
    'SET_SPONSOR_CONFIGURATION': 'SET_SPONSOR_CONFIGURATION',
    'CONFIGURE_ALERT': 'CONFIGURE_ALERT'
}
