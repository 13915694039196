const statusFiltersSecondAssessment = [
  {
    coverageType: 'SECOND_ASSESSMENT',
    states: [
      {text: 'authorizationList.status.pending-medical-report', value: 'PENDING_MEDICAL_REPORT', color: '#fcebcf', textColor: '#f0ac29'},
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.pending-review-sv-filter', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29', authorizationFormSlug: 'mx-metlife-sv-Documental' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED' },
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED' },
      {text: 'authorizationList.status.cancelled', value: 'CANCELLED'},
      {text: 'authorizationList.status.cancelled-by-patient', value: 'CANCELLED_BY_PATIENT'}
    ]
  }
]

const statusFiltersHospitalization = [
  {
    coverageType: 'HOSPITALIZATION',
    states: [
      {text: 'authorizationList.status.hospitalization.ready', value: 'READY', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.hospitalization.approved', value: 'APPROVED', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.hospitalization.error', value: 'ERROR' },
      {text: 'authorizationList.status.hospitalization.voided', value: 'VOIDED'},
      {text: 'authorizationList.status.hospitalization.notAttended', value: 'NOT_ATTENDED'},
      {text: 'authorizationList.status.hospitalization.sentToSponsor', value: 'SENT_TO_SPONSOR'},
    ]
  }
]


const status = [
  {
    coverageType: 'DEFAULT',
    states: [
      {text: 'authorizationList.status.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.ready', value: 'READY', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.partially-claimed', value: 'PARTIALLY_CLAIMED', color: '#fcebcf', textColor: '#f0ac29'},
      {text: 'authorizationList.status.request-in-progress', value: 'REQUEST_IN_PROGRESS'},
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
      {text: 'authorizationList.status.cancelled', value: 'CANCELLED'},
      {text: 'authorizationList.status.cancelled-by-patient', value: 'CANCELLED_BY_PATIENT'},
      {text: 'authorizationList.status.pending-medical-report', value: 'PENDING_MEDICAL_REPORT'},
      {text: 'authorizationList.status.notAttended', value: 'NOT_ATTENDED'},
      {text: 'authorizationList.status.sentToSponsor', value: 'SENT_TO_SPONSOR'},
    ]
  },
  {
    coverageType: 'CREDIT_INSURANCE',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED' },
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED' },
      {text: 'authorizationList.status.cancelled', value: 'CANCELLED'},
      {text: 'authorizationList.status.cancelled-by-patient', value: 'CANCELLED_BY_PATIENT'}
    ]
  },
  {
    coverageType: 'SECOND_ASSESSMENT',
    states: [
      {text: 'authorizationList.status.pending-medical-report', value: 'PENDING_MEDICAL_REPORT', color: '#fcebcf', textColor: '#f0ac29'},
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.pending-review-sv', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29', authorizationFormSlug: 'mx-metlife-sv-Documental' },
      {text: 'authorizationList.status.pending-review-sv-appointment-booked', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29', authorizationFormSlug: 'mx-metlife-sv-PoV' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED' },
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED' },
      {text: 'authorizationList.status.cancelled', value: 'CANCELLED'},
      {text: 'authorizationList.status.cancelled-by-patient', value: 'CANCELLED_BY_PATIENT'}
    ]
  },
  {
    coverageType: 'CREDIT_CARD_BALANCE',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'PERMANENT_DISABILITY',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'UNEMPLOYMENT',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'FUNERAL_ASSISTANCE',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'PPI',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'TEMPORARY_DISABILITY',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED' },
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f' },
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED' },
    ]
  },
  {
    coverageType: 'CANCER_DIAGNOSIS',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'DAILY_HOSPITALIZATION_INCOME',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'ACCIDENT_MEDICAL_REIMBURSEMENT',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'DIABETES_ACCIDENTAL_DEATH',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'DIABETES',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'CELL_PROTECTION',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ],
  },
  {
    coverageType: 'CASH_ROBBERY',
    states: [
      {text: 'authorizationList.status.credit-insurance.qualification', value: 'QUALIFICATION', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.pending-review', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.credit-insurance.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.credit-insurance.incomplete', value: 'INCOMPLETE', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.credit-insurance.rejected', value: 'REJECTED'},
      {text: 'authorizationList.status.credit-insurance.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.paid', value: 'PAID', color: '#e7effe', textColor: '#528ef7' },
      {text: 'authorizationList.status.error', value: 'ERROR' },
      {text: 'authorizationList.status.user-error', value: 'USER_ERROR'},
      {text: 'authorizationList.status.voided', value: 'VOIDED'},
    ]
  },
  {
    coverageType: 'HOSPITALIZATION',
    states: [
      {text: 'authorizationList.status.hospitalization.ready', value: 'READY', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.hospitalization.rejected', value: 'REJECTED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.hospitalization.qualification', value: 'QUALIFICATION', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.hospitalization.pendingReview', value: 'PENDING_REVIEW', color: '#fcebcf', textColor: '#f0ac29' },
      {text: 'authorizationList.status.hospitalization.approved', value: 'APPROVED', color: '#fafafa', textColor: '#4f4f4f'},
      {text: 'authorizationList.status.hospitalization.claimed', value: 'CLAIMED', color: '#fafafa', textColor: '#4f4f4f'}
    ]
  }
]

const icons = [
  {
    productStatus: 'APPROVED',
    icon: 'check_circle',
    color: 'primary'
  },
  {
    productStatus: 'PARTIALLY_APPROVED',
    icon: 'flaky',
    color: 'warning'
  },
  {
    productStatus: 'REJECTED',
    icon: 'cancel',
    color: 'error'
  },
]

const types = [
  {
    slug: 'PHARMACY',
    value: 'PHARMACY',
    text: 'authorizationList.types.pharmacy',
    icon: 'local_pharmacy'
  },
  {
    slug: 'PROCESS',
    value: 'PROCESS',
    text: 'authorizationList.types.process',
    icon: 'local_pharmacy'
  },
  {
    slug: 'PRESCRIPTION_DRUGS',
    value: 'PRESCRIPTION_DRUGS',
    text: 'authorizationList.types.prescription-drugs',
    icon: 'local_hospital'
  },
  {
    slug: 'DIAGNOSTIC_TESTS',
    value: 'DIAGNOSTIC_TESTS',
    text: 'authorizationList.types.diagnostic-test',
    icon: 'science'
  },
  {
    slug: 'SPORTS',
    value: 'SPORTS',
    text: 'authorizationList.types.sports',
    icon: 'sports_baseball'
  },
  {
    slug: 'EDUCATION',
    value: 'EDUCATION',
    text: 'authorizationList.types.education',
    icon: 'school'
  },
  {
    slug: 'WELLNESS',
    value: 'WELLNESS',
    text: 'authorizationList.types.wellness',
    icon: 'local_hospital'
  },
  {
    slug: 'CREDIT_CARD_BALANCE',
    value: 'CREDIT_CARD_BALANCE',
    text: 'authorizationList.types.credit-card-balance',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'OCCUPATIONAL_HAZARD',
    value: 'OCCUPATIONAL_HAZARD',
    text: 'authorizationList.types.occupational-hazard',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'PRESCRIPTION_DRUGS',
    value: 'PRESCRIPTION_DRUGS',
    text: 'authorizationList.types.drugs',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'MEDICAL_PROCEDURE',
    value: 'MEDICAL_PROCEDURE',
    text: 'authorizationList.types.medical-procedures',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'OUTPATIENT_CARE',
    value: 'OUTPATIENT_CARE',
    text: 'authorizationList.types.outpatient-care-claim',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'PERMANENT_DISABILITY',
    value: 'PERMANENT_DISABILITY',
    text: 'authorizationList.types.permanent-disability',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'UNEMPLOYMENT',
    value: 'UNEMPLOYMENT',
    text: 'authorizationList.types.unemployment',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'FUNERAL_ASSISTANCE',
    value: 'FUNERAL_ASSISTANCE',
    text: 'authorizationList.types.funeral-assistance',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'PPI',
    value: 'PPI',
    text: 'authorizationList.types.ppi',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'TEMPORARY_DISABILITY',
    value: 'TEMPORARY_DISABILITY',
    text: 'authorizationList.types.temporary-disability',
    icon: 'account_balance_wallet'
  },
  {
    slug: 'SECOND_ASSESSMENT',
    value: 'SECOND_ASSESSMENT',
    text: 'authorizationList.types.second-assessment',
    icon: 'how_to_reg'
  },
  {
    slug: 'CANCER_DIAGNOSIS',
    value: 'CANCER_DIAGNOSIS',
    text: 'authorizationList.types.cancer-diagnosis',
    icon: 'how_to_reg'
  },
  {
    slug: 'DAILY_HOSPITALIZATION_INCOME',
    value: 'HOSPITALIZATION_INCOME',
    text: 'authorizationList.types.hospitalization-income',
    icon: 'how_to_reg'
  },
  {
    slug: 'ACCIDENT_MEDICAL_REIMBURSEMENT',
    value: 'HOSPITALIZATION_INCOME',
    text: 'authorizationList.types.accident-medical-reimbursement',
    icon: 'how_to_reg'
  },
  {
    slug: 'DIABETES_ACCIDENTAL_DEATH',
    value: 'DIABETES_ACCIDENTAL_DEATH',
    text: 'authorizationList.types.diabetes-accidental-death',
    icon: 'how_to_reg'
  },
  {
    slug: 'DIABETES',
    value: 'DIABETES',
    text: 'authorizationList.types.diabetes',
    icon: 'how_to_reg'
  },
  {
    slug: 'CELL_PROTECTION',
    value: 'CELL_PROTECTION',
    text: 'authorizationList.types.cell-protection',
    icon: 'how_to_reg'
  },
  {
    slug: 'CASH_ROBBERY',
    value: 'CASH_ROBBERY',
    text: 'authorizationList.types.cash-robbery',
    icon: 'how_to_reg'
  },
  {
    slug: 'HOSPITAL_CARE',
    value: 'HOSPITAL_CARE',
    text: 'authorizationList.types.hopital-care',
    icon: 'how_to_reg'
  },
  {
    slug: 'HOSPITALIZATION',
    value: 'HOSPITALIZATION',
    text: 'authorizationList.types.hospitalization',
    icon: 'how_to_reg'
  },
]

const tabsDetail = [
  {
    tab: 'authorizationDetail.tabs.information',
    component: 'Information'
  },
  {
    tab: 'authorizationDetail.tabs.observations',
    component: 'Observations'
  }
]

const imageExtensions = [
  'jpeg', 'gif', 'png', 'apng', 'svg', 'bmp', 'bmp ico', 'png ico', 'jpg'
]

const disableAdditionalFilesButton = [
  'CLAIMED', 'REJECTED', 'PAID'
]

const orderBy = [
  {
    value: 'createdAt',
    text: 'authorizationList.header.date',
    direction: "DESC"
  },
  {
    value: 'coverageType',
    text: 'authorizationList.header.coverage',
    direction: "ASC"
  },
  {
    value: 'authorizationStatus',
    text: 'authorizationList.header.status',
    direction: "ASC"
  }
]

const showBeneficiaryHeaderByCoverageType = ['HOSPITALIZATION']

export {
  status,
  icons,
  types,
  tabsDetail,
  imageExtensions,
  disableAdditionalFilesButton,
  orderBy,
  statusFiltersSecondAssessment,
  showBeneficiaryHeaderByCoverageType,
  statusFiltersHospitalization
}
