<template>
  <v-dialog v-model="dialog" class="rounded-lg" width="490px" persistent>
    <v-container class="px-10 py-10 white">
      <v-row>
        <v-col>
          <p class="text-h5 secondary--text">{{ title }}</p>
          <p class="text-body-3 osg-gray--text">{{ subtitle }}</p>
        </v-col>
      </v-row>

      <v-row v-if="showTextArea">
          <v-col class="grey lighten-4 rounded-sm">
            <v-textarea
              v-model="description"
              persistent-hint
              clearable
            ></v-textarea>
          </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="4">
          <v-btn color="primary" outlined depressed rounded block @click="emitCancel">
            {{ cancel }}
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn color="error" depressed rounded block @click="emitAccept">
            {{ accept }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',

  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    subtitle: {
      type: String,
      default: '',
      required: false
    },
    showTextArea: {
      type: Boolean,
      default: false,
      required: false
    },
    autoClose: {
      type: Boolean,
      default: false,
      required: false
    },
    cancel: {
      type: String,
      default: '',
      required: false
    },
    accept: {
      type: String,
      default: '',
      required: false
    },
  },

  data: () => ({
    dialog: false,
    item: null,
    description: ''
  }),

  methods: {
    emitCancel(){
      this.item = false;
      this.dialog = false
    },
    emitAccept(){
      if(this.showTextArea){
        this.$emit('accept', this.addDescription());
        return;
      }

      this.$emit('accept', this.item);

      if(this.autoClose){
        this.dialog = false;
      }
    },
    open(item){
      this.dialog = true;
      this.item = item;
      this.description = '';
    },
    close(){
      this.dialog = false;
    },
    addDescription(){
      return {
        content: this.item,
        description: this.description
      }
    }
  }
}
</script>
