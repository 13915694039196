export const events = {
	VOID_AUTHORIZATION: 'VOID_AUTHORIZATION',
  UPDATE_AUTHORIZATION: 'UPDATE_AUTHORIZATION',
  NOT_ATTENDED: 'NOT_ATTENDED',
}

export const aurhotizationRestrictions = {
  'ADMIN': [
  ],
  'USER': [
    events.VOID_AUTHORIZATION,
    events.UPDATE_AUTHORIZATION,
    events.NOT_ATTENDED
  ]
}

export const getAllowedEventsByRole = (roles) => {
  const rolesArray = Array.isArray(roles) ? roles : roles?.split(',');
  let allowedEvents = Object.keys(events);

  rolesArray?.forEach(role => {
    const restrictions = aurhotizationRestrictions[role];

    allowedEvents = restrictions
  });

  return allowedEvents;
}
