import I18n from '@/plugins/i18n'
import Vue from 'vue'

export const sponsorsWithEnableSpecificTranslations = ['mx-mutuus'];

export const getLocales = () => {
  return [
    { nameKey: 'locales.es', locale: 'es' },
    { nameKey: 'locales.en', locale: 'en' },
  ];
}

export const getLocale = (locale) => {
  const defaultLocale = 'es';
  
  if(locale === undefined) {
    return defaultLocale
  }
  
  if (locale.length == 2 ) {
    return locale
  }

  if (locale.length > 2 ) {
    return I18n.availableLocales.includes(locale) ? extractLanguageCode(locale) : defaultLocale;
  }
  
  return defaultLocale;
}

function extractLanguageCode(locale) {
  return locale.split('_')[0];
}

Vue.prototype.$t = function (key, ...args) {
  const tokenExtensions = this.$store.state.auth.tokenExtensions || {};
  const sponsorSlug = tokenExtensions.sponsorSlug;
  const isEnabledSponsorTranslations = tokenExtensions.sponsorTranslations === 'true';

  if (sponsorsWithEnableSpecificTranslations.includes(sponsorSlug) && isEnabledSponsorTranslations) {
    const clientKey = `${sponsorSlug}.${key}`;

    if (this.$i18n.te(clientKey)) {
      return this.$i18n.t(clientKey, ...args);
    }
  }

  return this.$i18n.t(key, ...args);
};
