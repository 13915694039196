<template>
  <div>
    <v-footer color="white"
      app
      inset
      height="55"
      class="pt-0">
      <v-row>
        <v-col 
          class="px-0 pt-0">
          <v-divider class="pt-2"/>
          <span 
            class="text-caption text--secondary pr-2"
            :class="{'pl-2': isMobile, 'pl-6': !isMobile}">
            {{ new Date().getFullYear() }} Osigu
          </span>
          <v-btn @click="privacyDialog = !privacyDialog"
            text
            class="text-caption text--secondary px-2">{{ $t('footer.privacyLabel') }}</v-btn>    
          <v-select :items="locales"
            :item-text="item => $t(item.nameKey)"
            item-value="locale"
            v-model="$i18n.locale"
            menu-props="auto"
            solo
            flat
            dense
            class="locale-select mt-1 d-inline-block text-caption"
            autocomplete="nope"
            role="presentation"
            hide-details="auto">
          </v-select>

          <v-btn id="intercom_link" depressed fab dark right class="float-right error mr-2" width="36" height="36">
            <v-icon size="16">$osg-chat</v-icon>
          </v-btn>
          
          <v-btn class="float-right d-none d-sm-flex"
              @click="openLinkOnTab('https://osigu.com/')"
            icon>
            <v-icon size="18">$vuetify.icons.osg-website</v-icon>
          </v-btn>

          <v-btn class="float-right d-none d-sm-flex"
              @click="openLinkOnTab('https://www.instagram.com/weareosigu/')"
            icon>
            <v-icon size="18">$vuetify.icons.osg-instagram</v-icon>
          </v-btn>

          <v-btn class="float-right d-none d-sm-flex"
              @click="openLinkOnTab('https://www.linkedin.com/company/osigu')"
            icon>
            <v-icon size="18">$vuetify.icons.osg-linkedin</v-icon>
          </v-btn>

        </v-col>
        <!-- <v-col cols="auto" class="py-0 px-0">
          <v-btn id="intercom_link" depressed fab dark right class="float-right error mb-1" width="44" height="44">
            <v-icon size="16">$osg-chat</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { getLocales } from '@/utils'
import { createNamespacedHelpers } from 'vuex'
const authModule = createNamespacedHelpers('auth');

export default {
  name: 'OsiguFooter',
  components: {
    
  },
  data: () => ({
    locales: getLocales()
  }),
  methods: {
    openLinkOnTab(link) {
      window.open(link, '_blank');
    }
  },
  computed: {
    ...authModule.mapGetters(['tokenExtensions']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    }
  }
};
</script>


<style lang="scss" scoped>
.locale-select {
  max-width: 110px;
  opacity: 0.7;
}
</style>