import {config} from '@/plugins/env.config'
import { getAllowedEventsByRole } from '@/utils/role-permissions'

export default {
    accessToken: (state) => state.accessToken,
    tokenType: (state) => state.tokenType,
    refreshToken: (state) => state.refreshToken,
    isAuthenticated: (state) => !!state.accessToken,
    tokenExtensions: (state) => state.tokenExtensions,
    authStatus: (state) => state.authStatus,
    usedEmail: (state) => state.email,
    beneficiaryAttributes: (state) => [{
        key: 'beneficiary_id',
        value: state.tokenExtensions.beneficiaryId
    }],
    webTitle: (state) => state.tokenExtensions.beneficiariesWebTitle || config.webTitle,
    allowedEvents: (state) => {
        const { beneficiariesWebRole } = state.tokenExtensions;

        return getAllowedEventsByRole(beneficiariesWebRole);
    },
}
