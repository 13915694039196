import { render, staticRenderFns } from "./AlertV2.vue?vue&type=template&id=335ee118&scoped=true"
import script from "./AlertV2.vue?vue&type=script&lang=js"
export * from "./AlertV2.vue?vue&type=script&lang=js"
import style0 from "./AlertV2.vue?vue&type=style&index=0&id=335ee118&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335ee118",
  null
  
)

export default component.exports