<template>
  <v-snackbar
    v-model="toggleAlert"
    top
    light
    width="500"
    class="ma-0 d-flex"
    :timeout="alertTimeout"
    :color="alertColor"
  >
    <v-alert
      v-model="toggleAlert"
      dense
      :type="alertType"
      class="ma-0"
      :color="alertColor"
    >
      <template slot="prepend">
        <v-icon :color="fontColor">{{alertIcon}}</v-icon>
        <span
          :class="['text-body-2', 'font-weight-bold ml-5', colorTextClass]"
          >{{ alertMessage }}</span
        >
      </template>
    </v-alert>
  </v-snackbar>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const authorizationModule = createNamespacedHelpers('general');

export default {
  name: "AlertV2",

  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      require: false,
    },
    message: {
      type: String,
      default: () => "",
    },
  },

  data: () => ({
    toggle: null,
  }),
  methods: {
    ...authorizationModule.mapActions(["configureAlert"])
  },
  computed: {
    ...authorizationModule.mapGetters(["alertConfiguration"]),
    toggleAlert: {
      get() {
        return this.alertConfiguration.active || this.show;
      },
      set(val) {
        this.toggle = val;
      },
    },
    alertMessage() {
      return this.alertConfiguration.message || this.message;
    },
    alertType() {
      return this.alertConfiguration.type || this.type;
    },
    alertTimeout() {
      return this.alertConfiguration.timeout;
    },
    colorTextClass() {
      switch (this.alertConfiguration.type) {
        case "error":
          return 'osg-error-text'
        case 'success':
          return "osg-success-text"
        default:
          return 'primary'
      }
    },
    fontColor(){
      switch (this.alertConfiguration.type) {
        case "error":
          return '#ff726d'
        case 'success':
          return "#08b192"
        default:
          return 'white'
      }
    },
    alertColor(){
      switch (this.alertConfiguration.type) {
        case "error":
          return '#ffe6e5'
        case 'success':
          return "#bcefe6"
        default:
          return '#1f3261'
      }
    },
    alertIcon() {
      switch (this.alertConfiguration.type) {
        case "error":
          return 'error'
        case 'success':
          return 'check_circle'
        default:
          return 'error'
      }
    }
  },
  watch: {
    toggleAlert: function(){
      if(this.toggleAlert){
        if(this.alertConfiguration.timeout){
          setTimeout(() => {
            this.configureAlert({
              active: false,
              message: null
            })
          }, this.alertConfiguration.timeout);
        }
      }
    }
  }
};
</script>
<style scoped>
.osg-error-text{
  color: #ff726d !important;
}
.osg-success-text{
  color: #08b192 !important;
}
</style>