import store from '@/plugins/vuex'
const Login = () => import('@/components/login/Login');
const ForgotPassword = () => import('@/components/forgot-password/ForgotPassword');
const ChangePassword = () => import('@/components/change-password/ChangePassword');
const Home = () => import('@/components/home/Home');
const HomeLayout = () => import('@/components/home-layout/HomeLayout');
const AddAuthorizationLayout = () => import('@/components/authorization/add-authorization/AddAuthorizationLayout');
const AuthorizationDetailLayout = () => import('@/components/authorization/authorization-detail/AuthorizationDetailLayout');
const AgreementDocumentView = () => import('@/components/agreement/AgreementDocumentView');

const HomeLayoutV2 = () => import('@/components/v2/home/HomeLayoutV2');
const HomeV2 = () => import('@/components/v2/home/HomeV2');
const AddAuthorizationLayoutV2 = () => import('@/components/v2/authorization/new/AddAuthorizationLayoutV2');
const AuthorizationDetailLayoutV2 = () => import('@/components/v2/authorization/detail/AuthorizationDetailLayout');

const onlyAuthenticatedGuard = (to, from, next) => {
  const getters = store.getters

  const isAuthenticated = getters['auth/isAuthenticated']

  if(!isAuthenticated){
    return next('/login')
  }

  return next()
}

const areUserPrerequisitesValid = (to, from, next) => {
  const getters = store.getters
  const isAuthenticated = getters['auth/isAuthenticated']
  const hasPendingDocumentsToAgree = getters['agreements/hasPendingDocumentsToAgree']

  const tokenExtensions = getters['auth/tokenExtensions']
  const webVersion = tokenExtensions.beneficiariesWebVersion

  if(!isAuthenticated){
    return next('/login')
  }

  if(hasPendingDocumentsToAgree) {
    return next('/agreement')
  }

  if(webVersion === 'v2'){
    return next({ name: 'HomeV2' })
  }

  return next()
}

const areUserPrerequisitesValidV2 = (to, from, next) => {
  const getters = store.getters
  const isAuthenticated = getters['auth/isAuthenticated']
  const hasPendingDocumentsToAgree = getters['agreements/hasPendingDocumentsToAgree']

  const tokenExtensions = getters['auth/tokenExtensions']
  const webVersion = tokenExtensions.beneficiariesWebVersion

  if(!isAuthenticated){
    return next('/login')
  }

  if(hasPendingDocumentsToAgree) {
    return next('/agreement')
  }

  if(!webVersion === 'v2'){
    return next('/')
  }

  return next()
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/',
    component: HomeLayout,
    beforeEnter: areUserPrerequisitesValid,
    redirect: { name: 'Home' },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        beforeEnter: areUserPrerequisitesValid,
      },
      {
        path: '/agreement',
        name: 'AgreementDocuments',
        component: AgreementDocumentView,
        beforeEnter: onlyAuthenticatedGuard,
      },
      {
        path: '/authorization/new',
        name: 'AddAuthorization',
        component: AddAuthorizationLayout,
        beforeEnter: areUserPrerequisitesValid,
      },
      {
        path: '/authorization/:authorization',
        name: 'AuthorizationDetail',
        component: AuthorizationDetailLayout,
        beforeEnter: areUserPrerequisitesValid,
      },
      {
        path: '*',
        redirect: { name: 'Home' },
      }
    ]
  },
  {
    path: '/v2',
    component: HomeLayoutV2,
    beforeEnter: areUserPrerequisitesValidV2,
    redirect: { name: 'HomeV2' },
    children: [
      {
        path: 'home',
        name: 'HomeV2',
        component: HomeV2,
        beforeEnter: areUserPrerequisitesValidV2,
      },
      {
        path: 'authorization/new',
        name: 'AddAuthorizationV2',
        component: AddAuthorizationLayoutV2,
        beforeEnter: areUserPrerequisitesValidV2,
      },
      {
        path: 'authorization/:authorization',
        name: 'AuthorizationDetailV2',
        component: AuthorizationDetailLayoutV2,
        beforeEnter: areUserPrerequisitesValidV2,
      },
      {
        path: '*',
        redirect: { name: 'HomeV2' },
      }
    ]
  }
]

export default routes;
