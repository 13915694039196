<template>
  <v-container fluid>
    <v-row v-if="!listAuthorizationSelected">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular
          :size="70"
          class="text-center"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-container>
        <DetailHeader
          :authorizationCode="authorization.authorizationCode"
          :createdAt="lastUpdate"
          :status="$t(`authorizationDetail.headerDetail.status.${authorization.status}`)"
        />

        <v-col cols="12" md="10" offset-md="2">
          <DynamicComponent
            v-for="field in fields"
            :key="field.id"
            :field="field"
            :values="values"
            :coverageType="coverageType"
            :files="files"
          />
        </v-col>
      </v-container>

    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { tabsDetail } from '@/config/authorization'

import moment from 'moment'
import DetailHeader from '@/components/v2/authorization/detail/dynamic-components/header/DetailHeader'
import DynamicComponent from '@/components/v2/authorization/detail/dynamic-components/DynamicComponent.vue'

const authorizationModule = createNamespacedHelpers('authorizationV2')

export default {
  name: 'AuthorizationDetail',
  components: {
    DetailHeader,
    DynamicComponent
  },
  data() {
    return {
      tab: null
    }
  },
  filters: {
    dateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    }
  },
  methods: {
    ...authorizationModule.mapActions(['setSingleAuthorization']),
    showIcon(status) {
      return status === 'CLAIMED' || status === 'READY'
    },
    onBack() {
      this.setSingleAuthorization({})
      this.$router.push('/home')
    }
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization', 'authorizationForms']),
    items() {
      return tabsDetail
    },
    listAuthorizationSelected() {
      return Object.keys(this.authorization).length > 0
    },
    lastUpdate() {
      return moment(this.authorization.createdAt).format('DD/MM/YYYY HH:mm')
    },
    fields() {
      const form = this.authorizationForms.find(
        form => form.authorizationCoverage === 'HOSPITALIZATION'
      )

      if (form) {
        return form?.fields || []
      }
      return []
    },
    values() {
      return this?.authorization?.authorizationInputs || []
    },

    files() {
      return this?.authorization?.authorizationFiles || []
    },

    coverageType() {
      const form = this.authorizationForms.find(
        form => form.authorizationCoverage === 'HOSPITALIZATION'
      )

      return form?.authorizationCoverage || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
.font-size-1-3 {
  font-size: 1.3rem !important;
}
.font-size-0-75 {
  font-size: 0.75rem !important;
}
</style>
