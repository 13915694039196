import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locale', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {...messages[locale], ...locales(key)}
    }
  })

  return messages
}

const dateTimeFormats = {
  'en': {
    short: {
      year: '2-digit', month: '2-digit', day: '2-digit'
    },
    long: {
      year: '2-digit', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: true
    }
  },
  'es': {
    short: {
      year: '2-digit', month: '2-digit', day: '2-digit'
    },
    long: {
      year: '2-digit', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: true
    }
  }
}

export default new VueI18n({
  locale: 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages: loadLocaleMessages(),
  dateTimeFormats,
  silentFallbackWarn: true
})

